import 'whatwg-fetch';
import { buildBody, buildHeaders } from './builder';

const defaultOptions = () => (
  {
    headers: buildHeaders(),
    credentials: 'same-origin',
  }
);

const checkStatus = (response) => {
  if (response.ok) return response;

  return Promise.reject(response.json());
};

const parseJSON = (response) => {
  if (response.status === 204) return {};

  return response.json();
};

const processError = error => (
  error.then((content) => {
    if (content.hasOwnProperty('path')) {
      window.location.replace(content.path);
    } else {
      throw error;
    }
  })
);

export function get(url) {
  return fetch(url, {
    ...defaultOptions(),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(processError);
}

export function post(url, data, snakeCase = true) {
  return fetch(url, {
    method: 'POST',
    body: buildBody(data, snakeCase),
    ...defaultOptions(),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(processError);
}

export function patch(url, data, snakeCase = true) {
  return fetch(url, {
    method: 'PATCH',
    body: buildBody(data, snakeCase),
    ...defaultOptions(),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(processError);
}

export function destroy(url, data = null) {
  return fetch(url, {
    method: 'DELETE',
    ...(data && { body: buildBody(data) }),
    ...defaultOptions(),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(processError);
}

export function logout(url, redirectUrl) {
  return fetch(url, {
    method: 'DELETE',
    redirect: 'follow',
    ...defaultOptions(),
  })
    .then((response) => {
      if (response.redirected) {
        window.location.replace(redirectUrl);
      }
    });
}
