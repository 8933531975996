import { toSnakeCase } from 'utils/helpers';

const isHash = value => value instanceof Object && !(value instanceof File);

const isArrayWithoutObjects = (fieldName, value) =>
  Number.isInteger(parseInt(fieldName, 10)) &&
    (typeof value === 'string' || typeof value === 'number');

const isSelectWithOptionGroup = (fieldName, value) =>
  typeof fieldName === 'string' && fieldName.includes('___') &&
    typeof value === 'string' && value.includes('---');

const isValid = value => value !== null && value !== undefined;

function getFormDataKey(fieldName, prefixNames = [], isArray = false) {
  const prefixes = prefixNames.slice(0);

  if (prefixes.length) {
    let key = toSnakeCase(prefixes.shift());
    let name = prefixes.shift();

    while (name) {
      key += `[${toSnakeCase(name)}]`;
      name = prefixes.shift();
    }

    key = `${key}[${toSnakeCase(fieldName)}]`;
    if (isArray) key += '[]';

    return key;
  }

  return isArray ? `${toSnakeCase(fieldName)}[]` : toSnakeCase(fieldName);
}

function getFormDataKeySnakeCase(fieldName, prefixNames = [], isArray = false) {
  const prefixes = prefixNames.slice(0);

  if (prefixes.length) {
    let key = prefixes.shift();
    let name = prefixes.shift();

    while (name) {
      key += `[${name}]`;
      name = prefixes.shift();
    }

    key = `${key}[${fieldName}]`;
    if (isArray) key += '[]';

    return key;
  }

  return isArray ? `${fieldName}[]` : fieldName;
}

function getTransformKey(fieldName, snakeCase = true, prefixNames = [], isArray = false) {
  if (snakeCase) {
    return getFormDataKey(fieldName, prefixNames, isArray);
  }

  return getFormDataKeySnakeCase(fieldName, prefixNames, isArray);
}

function authenticityToken() {
  const token = document.querySelector('meta[name="csrf-token"]');

  if (token && token instanceof window.HTMLMetaElement) {
    return token.content;
  }

  return null;
}

export const buildHeaders = () => (
  {
    Accept: 'application/json',
    'x-csrf-token': authenticityToken(),
  }
);

export function buildBody(data, snakeCase = true, fd = new FormData(), prefixNames = []) {
  Object.keys(data).forEach((fieldName) => {
    const value = data[fieldName];

    if (isHash(value)) {
      const keys = Object.keys(value);

      if (keys.includes('label') && keys.includes('value')) {
        if (Number.isInteger(parseInt(fieldName, 10))) {
          const name = prefixNames.pop();

          fd.append(getTransformKey(name, snakeCase, prefixNames, true), value.value);
          prefixNames.push(name);
        } else {
          fd.append(getTransformKey(fieldName, snakeCase, prefixNames), value.value);
        }
      } else {
        buildBody(value, snakeCase, fd, prefixNames.concat([fieldName]));
      }
    } else if (isArrayWithoutObjects(fieldName, value)) {
      const name = prefixNames.pop();

      fd.append(getTransformKey(name, snakeCase, prefixNames, true), value);
      prefixNames.push(name);
    } else if (isSelectWithOptionGroup(fieldName, value)) {
      const names = fieldName.split('___');
      const values = value.split('---');

      fd.append(getTransformKey(names[0], snakeCase, prefixNames), values[0]);
      fd.append(getTransformKey(names[1], snakeCase, prefixNames), values[1]);
    } else if (isValid(value)) {
      fd.append(getTransformKey(fieldName, snakeCase, prefixNames), value);
    }
  });

  return fd;
}
