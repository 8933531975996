export const cleanText = (value) => {
  if (!value) return '';

  return value.replace(/[\v\f\r]/g, '')
    .replace(/[`‘’]/g, "'")
    .replace(/[“”]/g, '"')
    .replace(/\t/g, '  ')
    .replace(/…/g, '...')
    .replace(/•/g, ' ')
    .replace(/\r/, '')
    .replace(/:/g, ':')
    .replace(/--/g, '- -')
    .replace(/--/g, '- -');
};

export const cleanFileName = (file) => {
  if (!file) return '';

  const fileName = file.name.replace(/(?:\.(?![^.]+$)|[^\w\d\n.])+/gm, '_');
  const blob = file.slice(0, file.size);
  const newFile = new File([blob], fileName, { type: `${file.type}` });

  return newFile
};

export const cleanPhone = phone => phone.replace(/\D/g, '');

export const range = (min, max) => (value) => {
  if (value !== null && value !== undefined) {
    if (Number.isInteger(min) && value < min) {
      return min;
    } else if (Number.isInteger(max) && value > max) {
      return max;
    }
  }

  return value;
};
