import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  klass: PropTypes.string,
};

const defaultProps = {
  name: 'huge-heart',
  color: 'text-current',
  size: 'xsmall',
  klass: '',
};

function Icon(props) {
  const { name, size, color, klass } = props;
  return (
    <i className={`huge-icon ${name} is-${size} ${color} ${klass}`} />
  );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
